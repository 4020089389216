import React from "react"

import Layout from "../modules/layout"
import Casos from "../modules/m20_casos"
import InnerHero from "../modules/m14_InnerHero"
import BlockInner from "../components/blockinner"
import Card from "../components/cardCaso"
import datacasos from "../data/casos"

//import { Link, graphql } from "gatsby"

const CasosPage = () => {
  return (
    <Layout
      title="Convertir Local en Vivienda | Living Alternatives-Casos Prácticos"
      desc="Descubre los casos prácticos de éxito de Living Alternatives con una selección de locales comerciales convertidos en viviendas en la ciudad de Madrid. "
      keywords="convertir local en vivienda,
    convertir local en vivienda madrid,
    locales comerciales convertidos en viviendas,
    locales convertidos en vivienda madrid,
    se puede convertir un local comercial en vivienda"
      url="convertir-local-en-vivienda-madrid-casos-practicos"
    >
      <InnerHero
        bc="Casos"
        title="Casos prácticos"
        tagidbotshare="TM28-CasosPracticos-MóduloCompartir-BotonCompartir"
      />
      <BlockInner>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM29-CasosPracticos-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[1]}
            tagmanagerid="TM30-CasosPracticos-MóduloCasosPracticos-SanVenancio"
            title="De un local en bruto a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[2]}
            tagmanagerid="TM31-CasosPracticos-MóduloCasosPracticos-PuertoReal"
            title="De una clínica dental a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[3]}
            tagmanagerid="TM32-CasosPracticos-MóduloCasosPracticos-Zuera"
            title="De una pescadería a un estudio"
          ></Card>
        </Casos>
      </BlockInner>
    </Layout>
  )
}

export default CasosPage

/*
export const pageQuery = graphql`
  query IndexQuery {
    allStrapiCaso {
      edges {
        node {
          id
          title
          description
          price
          size
          wc
          rooms
          location {
            city
            postal
            address
            country
          }
        }
      }
    }
  }
`
*/
